/**
 * Generated bundle index. Do not edit.
 */

export * from './index';

export {APPLICATION_MODULE_PROVIDERS as ɵangular_packages_core_core_z,_iterableDiffersFactory as ɵangular_packages_core_core_w,_keyValueDiffersFactory as ɵangular_packages_core_core_x,_localeFactory as ɵangular_packages_core_core_y,zoneSchedulerFactory as ɵangular_packages_core_core_ba} from './src/application_module';
export {_appIdRandomProviderFactory as ɵangular_packages_core_core_g} from './src/application_tokens';
export {injectChangeDetectorRef as ɵangular_packages_core_core_r} from './src/change_detection/change_detector_ref';
export {DefaultIterableDifferFactory as ɵangular_packages_core_core_s} from './src/change_detection/differs/default_iterable_differ';
export {DefaultKeyValueDifferFactory as ɵangular_packages_core_core_t} from './src/change_detection/differs/default_keyvalue_differ';
export {defaultIterableDiffersFactory as ɵangular_packages_core_core_u} from './src/change_detection/differs/iterable_differs';
export {defaultKeyValueDiffersFactory as ɵangular_packages_core_core_v} from './src/change_detection/differs/keyvalue_differs';
export {DebugElement__PRE_R3__ as ɵangular_packages_core_core_p,DebugNode__PRE_R3__ as ɵangular_packages_core_core_o,getDebugNodeR2__PRE_R3__ as ɵangular_packages_core_core_q} from './src/debug/debug_node';
export {isForwardRef as ɵangular_packages_core_core_a} from './src/di/forward_ref';
export {getInjectImplementation as ɵangular_packages_core_core_bx} from './src/di/inject_switch';
export {attachInjectFlag as ɵangular_packages_core_core_c,injectInjectorOnly as ɵangular_packages_core_core_b} from './src/di/injector_compatibility';
export {InjectorMarkers as ɵangular_packages_core_core_by} from './src/di/injector_marker';
export {DecoratorFlags as ɵangular_packages_core_core_bt,InternalInjectFlags as ɵangular_packages_core_core_bu} from './src/di/interface/injector';
export {NullInjector as ɵangular_packages_core_core_bw} from './src/di/null_injector';
export {ReflectiveInjector_ as ɵangular_packages_core_core_d} from './src/di/reflective_injector';
export {ReflectiveDependency as ɵangular_packages_core_core_e,resolveReflectiveProviders as ɵangular_packages_core_core_f} from './src/di/reflective_provider';
export {USD_CURRENCY_CODE as ɵangular_packages_core_core_bb} from './src/i18n/localization';
export {createElementRef as ɵangular_packages_core_core_j,injectElementRef as ɵangular_packages_core_core_i} from './src/linker/element_ref';
export {getModuleFactory__PRE_R3__ as ɵangular_packages_core_core_k} from './src/linker/ng_module_factory_loader';
export {createTemplateRef as ɵangular_packages_core_core_m,injectTemplateRef as ɵangular_packages_core_core_l} from './src/linker/template_ref';
export {injectViewContainerRef as ɵangular_packages_core_core_n} from './src/linker/view_container_ref';
export {injectRenderer2 as ɵangular_packages_core_core_h} from './src/render/api';
export {SCHEDULER as ɵangular_packages_core_core_bf} from './src/render3/component_ref';
export {injectAttributeImpl as ɵangular_packages_core_core_bg} from './src/render3/di';
export {NgOnChangesFeatureImpl as ɵangular_packages_core_core_be} from './src/render3/features/ng_onchanges_feature';
export {i18nPostprocess as ɵangular_packages_core_core_cc} from './src/render3/i18n/i18n_postprocess';
export {TElementNode as ɵangular_packages_core_core_bk} from './src/render3/interfaces/node';
export {LView as ɵangular_packages_core_core_ca} from './src/render3/interfaces/view';
export {pureFunction1Internal as ɵangular_packages_core_core_bl,pureFunction2Internal as ɵangular_packages_core_core_bm,pureFunction3Internal as ɵangular_packages_core_core_bn,pureFunction4Internal as ɵangular_packages_core_core_bo,pureFunctionVInternal as ɵangular_packages_core_core_bp} from './src/render3/pure_function';
export {getBindingRoot as ɵangular_packages_core_core_bi,getLView as ɵangular_packages_core_core_bh,nextContextImpl as ɵangular_packages_core_core_bj} from './src/render3/state';
export {getRootContext as ɵangular_packages_core_core_cb} from './src/render3/util/view_traversal_utils';
export {getNativeByTNode as ɵangular_packages_core_core_bz} from './src/render3/util/view_utils';
export {getUrlSanitizer as ɵangular_packages_core_core_bq} from './src/sanitization/sanitization';
export {makeParamDecorator as ɵangular_packages_core_core_bs,makePropDecorator as ɵangular_packages_core_core_br} from './src/util/decorators';
export {getClosureSafeProperty as ɵangular_packages_core_core_bv} from './src/util/property';
export {_def as ɵangular_packages_core_core_bc} from './src/view/provider';
export {DebugContext as ɵangular_packages_core_core_bd} from './src/view/types';